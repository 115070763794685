import $ from 'jquery';

$(document).ready(function () {

  $('.hero__row').slick({
    dots: false,
    // centerMode: true,
    // centerPadding: '0px',
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    arrows: false,
    lazyLoad: 'progressive',

  });
  // responsive: [
  //   {
  //     breakpoint: 480,
  //     settings: {
  //       arrows: false,
  //       centerMode: true,
  //       centerPadding: '40px',
  //       slidesToShow: 1,
  //     },
  //   },
  //   {
  //     breakpoint: 768,
  //     settings: {
  //       arrows: false,
  //       centerMode: true,
  //       centerPadding: '40px',
  //       slidesToShow: 2,
  //     },
  //   },
  //   {
  //     breakpoint: 1440,
  //     settings: {
  //       arrows: false,
  //       centerMode: true,
  //       centerPadding: '40px',
  //       slidesToShow: 3,
  //     },
  //   },
  // ],

});
