/* Please ❤ this if you like it! */
// import jQuery from 'jquery';
// (function ($) {
//   'use strict';

//   //Switch dark/light

//   $('.switch').on('click', function () {
//     if ($('body').hasClass('light')) {
//       $('body').removeClass('light');
//       $('.switch').removeClass('switched');
//     } else {
//       $('body').addClass('light');
//       $('.switch').addClass('switched');
//     }
//   });

//   $(document).ready(function () {
//     'use strict';

//     //Scroll back to top

//     var progressPath = document.querySelector('.progress-wrap path');
//     var pathLength = progressPath.getTotalLength();
//     progressPath.style.transition = progressPath.style.WebkitTransition =
//       'none';
//     progressPath.style.strokeDasharray = pathLength + ' ' + pathLength;
//     progressPath.style.strokeDashoffset = pathLength;
//     progressPath.getBoundingClientRect();
//     progressPath.style.transition = progressPath.style.WebkitTransition =
//       'stroke-dashoffset 10ms linear';
//     var updateProgress = function () {
//       var scroll = $(window).scrollTop();
//       var height = $(document).height() - $(window).height();
//       var progress = pathLength - (scroll * pathLength) / height;
//       progressPath.style.strokeDashoffset = progress;
//     };
//     updateProgress();
//     $(window).scroll(updateProgress);
//     var offset = 150;
//     var duration = 150;
//     jQuery(window).on('scroll', function () {
//       if (jQuery(this).scrollTop() > offset) {
//         jQuery('.progress-wrap').addClass('active-progress');
//       } else {
//         jQuery('.progress-wrap').removeClass('active-progress');
//       }
//     });
//     jQuery('.progress-wrap').on('click', function (event) {
//       event.preventDefault();
//       jQuery('html, body').animate({ scrollTop: 0 }, duration);
//       return false;
//     });
//   });
// })(jQuery);


(() => {
  window.addEventListener('scroll', function () {
    const header = document.querySelector('.header');
    const product = document.querySelector('.products');
    const scrollPosition = window.scrollY;
    const result = scrollPosition + header.offsetHeight
    const data = {
      scrollPosition,
      productTop: product.offsetTop,
      headerHeight: header.offsetHeight,
      result
    }
    const howmuch = data.result - data.productTop - 8;
    if (data.result >= data.productTop) {
      const toTop = howmuch > header.offsetHeight ? header.offsetHeight - 5 : howmuch - 5;
      header.style.top = `-${toTop}px`;
    } else {
      header.style.top = `0px`;
    }

  });

})()

